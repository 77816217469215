<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import blocsTable from './components/blocsTable';
export default {
  page: {
    title: "Blocs",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, blocsTable },
  data() {
    return {
      tableData: [],
      title: "Blocs",
      blocs: [],
      items: [
        {
          text: "Infrastructure",
          href: "javascript:void(0)"
        },
        {
          text: "Blocs",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ]
    };
  },
  computed: {

    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    this.totalRows = this.tableData.length;

    this.refreshBlocsTable();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    refreshBlocsTable(){
      this.$http.post('/infrastructure/blocs/list')
            .then(res => (this.blocs = res.data.original.list))
            .catch(() => {
                // error.response.status Check status code
                
            }).finally(() => {
                //Perform action in always
            });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <a @click="refreshBlocsTable()" href="javascript:void()"><h1><i class="bx bx-rotate-right" style="float: right;"></i> </h1></a>
            <router-link v-if="$can('create_administration_bloc')" :to="{ name: 'base.infrastructures.blocs.new'}"><b-button variant="primary">Nouveau bloc</b-button></router-link>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Recherche..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <blocsTable :blocs="blocs"/>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>